
import ModalButton from '@/components/UntitledUI/Button.vue'
import Modal from '@/components/common/Modals/Modal.vue'
import Icon from '@/components/UntitledUI/Icon.vue'

export default {
    name: 'OwnerApprovedTrialModal',
    components: { ModalButton, Modal, Icon },
    props: {
        redirectOnClose: {
            type: Boolean,
            default: false
        }
    },
    mounted () {
        this.$axios.post('/v1/english-interests/popup')
            .catch(() => { /** Do nothing */ })
    },
    methods: {
        startNow () {
            window.location.href = 'en/Search'
        },
        close () {
            if (this.redirectOnClose) {
                this.startNow()
            } else {
                this.$emit('close')
            }
        }
    }
}
