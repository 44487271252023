
import tippyJs from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/translucent.css'

export default {
    name: 'Tippy',
    props: {
        allowHTML: {
            type: Boolean,
            default: false
        },
        animateFill: {
            type: Boolean,
            default: null
        },
        animation: {
            type: [String, Boolean],
            default: 'fade'
        },
        appendTo: {
            type: null,
            default: null
        },
        aria: {
            type: Object,
            default: () => ({})
        },
        arrow: {
            type: null,
            default: true
        },
        content: {
            type: null,
            default: ''
        },
        delay: {
            type: [Number, Array],
            default: 0
        },
        duration: {
            type: [Number, Array],
            default: () => [300, 250]
        },
        followCursor: {
            type: [Boolean, String],
            default: null
        },
        getReferenceClientRect: {
            type: Function,
            default: null
        },
        hideOnClick: {
            type: [Boolean, String],
            default: true
        },
        ignoreAttributes: {
            type: Boolean,
            default: false
        },
        inertia: {
            type: Boolean,
            default: false
        },
        inlinePositioning: {
            type: Boolean,
            default: null
        },
        interactive: {
            type: Boolean,
            default: false
        },
        interactiveBorder: {
            type: Number,
            default: 2
        },
        interactiveDebounce: {
            type: Number,
            default: 0
        },
        maxWidth: {
            type: [Number, String],
            default: 350
        },
        moveTransition: {
            type: String,
            default: ''
        },
        offset: {
            type: Array,
            default: () => [0, 10]
        },
        onAfterUpdate: {
            type: Function,
            default: null
        },
        onBeforeUpdate: {
            type: Function,
            default: null
        },
        onClickOutside: {
            type: Function,
            default: null
        },
        onCreate: {
            type: Function,
            default: null
        },
        onDestroy: {
            type: Function,
            default: null
        },
        onHidden: {
            type: Function,
            default: null
        },
        onHide: {
            type: Function,
            default: null
        },
        onMount: {
            type: Function,
            default: null
        },
        onShow: {
            type: Function,
            default: null
        },
        onShown: {
            type: Function,
            default: null
        },
        onTrigger: {
            type: Function,
            default: null
        },
        onUntrigger: {
            type: Function,
            default: null
        },
        placement: {
            type: String,
            default: 'top'
        },
        plugins: {
            type: Array,
            default: () => []
        },
        popperOptions: {
            type: Object,
            default: () => ({})
        },
        render: {
            type: Function,
            default: null
        },
        role: {
            type: String,
            default: 'tooltip'
        },
        showOnCreate: {
            type: Boolean,
            default: false
        },
        sticky: {
            type: [Boolean, String],
            default: null
        },
        theme: {
            type: String,
            default: ''
        },
        touch: {
            type: [Boolean, String, Array],
            default: true
        },
        trigger: {
            type: String,
            default: 'mouseenter focus'
        },
        triggerTarget: {
            type: null,
            default: null
        },
        zIndex: {
            type: Number,
            default: 9999
        }
    },
    data () {
        return {
            tippy: null,
            isMounted: false
        }
    },
    mounted () {
        this.isMounted = true
        this.init()
    },
    updated () {
        if (this.isMounted) {
            try {
                this.tippy?.setProps(this.getOptions())
            } catch (e) {}
        }
    },
    beforeDestroy () {
        try {
            if (this.tippy) {
                this.tippy?.destroy()
            }
        } catch (e) {}
    },
    methods: {
        init () {
            if (this.tippy) {
                try {
                    this.tippy.destroy()
                } catch (error) { }

                this.tippy = null
            }
            const reference = this.triggerTarget || (Array.isArray(this.triggerTarget) && this.triggerTarget.length > 0)
                ? this.triggerTarget
                : this.$refs.default
            this.tippy = tippyJs(reference, this.getOptions())
        },
        show () {
            this.tippy?.show()
        },
        hide () {
            this.tippy?.hide()
        },
        getOptions () {
            const options = {}
            for (const propsKey in this.$props) {
                if ([undefined, null].includes(this.$props[propsKey])) {
                    continue
                }
                if ((propsKey === 'render' || propsKey.startsWith('on')) && typeof this.$props[propsKey] !== 'function') {
                    continue
                }
                if (propsKey === 'triggerTarget' && typeof this.$props[propsKey] === 'string') {
                    const target = this.$el.querySelector(this.triggerTarget) || this.$el.closest(this.triggerTarget)
                    if (target) {
                        options[propsKey] = target
                    }
                    continue
                }
                options[propsKey] = this[propsKey]
            }
            options.allowHTML = this.$slots.content ? true : options.allowHTML
            options.content = this.$slots.content ? this.$refs.content : options.content
            options.appendTo = options.appendTo ?? document.body
            return options
        }
    }
}
