
import HeaderBar from '@/components/Layout/HeaderBar.vue'
import SideBar from '~/components/Layout/SideBar.vue'
import { tippy } from '~/Utils/directives'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

export default {
    components: { HeaderBar, SideBar },
    directives: { tippy },
    data () {
        return {
            subscription: null,
            showSidebar: false,
            isExpanded: false
        }
    },
    mounted () {
        this.loadSubscription()
    },
    methods: {
        async loadSubscription () {
            if (!this.$auth.loggedIn || this.$auth?.user?.is_admin) {
                return
            }

            if (this.$auth.user.is_tenant_owner) {
                const form = new this.$vForm()
                const { data } = await form.get('v1/CurrentSubscription')
                if (!data) {
                    this.$router.push('/Packages')
                }
            }
        }
    },
    head () {
        return {
            titleTemplate: '%s | ' + this.$t('app name'),
            ...this.$nuxtI18nHead({
                addDirAttribute: true
            })
        }
    }
}
