export default function ({ query, app }) {
    try {
        if (process.server) {
            const cookies = app.$cookies
            const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

            let utmData = []
            try {
                const existingUtmData = cookies.get('utm_params')
                if (existingUtmData && Array.isArray(existingUtmData)) {
                    utmData = existingUtmData
                }
            } catch (error) {
                console.error('Error parsing utm_params cookie:', error)
            }

            const newUtm = {}
            utmParams.forEach((param) => {
                if (query[param]) {
                    newUtm[param] = query[param]
                }
            })

            if (Object.keys(newUtm).length > 0) {
                newUtm.date = new Date().toISOString().split('T')[0]

                const isDuplicate = utmData.some((utm) => {
                    return utmParams.every(param => utm[param] === newUtm[param])
                })

                if (!isDuplicate) {
                    if (utmData.length === 0) {
                        newUtm.status = 'first_visit'
                    }
                    utmData.push(newUtm)
                }
            }

            if (utmData.length > 0) {
                cookies.set('utm_params', utmData, {
                    path: '/',
                    maxAge: 6 * 30 * 24 * 60 * 60, // 6 months
                })
            }
        }
    } catch (error) {
        console.error('Error in UTM middleware:', error)
    }
}
