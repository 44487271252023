const middleware = {}

middleware['hasNoTeam'] = require('../middleware/hasNoTeam.js')
middleware['hasNoTeam'] = middleware['hasNoTeam'].default || middleware['hasNoTeam']

middleware['hasTeam'] = require('../middleware/hasTeam.js')
middleware['hasTeam'] = middleware['hasTeam'].default || middleware['hasTeam']

middleware['hasTranslationFeature'] = require('../middleware/hasTranslationFeature.js')
middleware['hasTranslationFeature'] = middleware['hasTranslationFeature'].default || middleware['hasTranslationFeature']

middleware['intercomUpdate'] = require('../middleware/intercomUpdate.js')
middleware['intercomUpdate'] = middleware['intercomUpdate'].default || middleware['intercomUpdate']

middleware['isAdmin'] = require('../middleware/isAdmin.js')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['isNotSubscribe'] = require('../middleware/isNotSubscribe.js')
middleware['isNotSubscribe'] = middleware['isNotSubscribe'].default || middleware['isNotSubscribe']

middleware['isSubscribe'] = require('../middleware/isSubscribe.js')
middleware['isSubscribe'] = middleware['isSubscribe'].default || middleware['isSubscribe']

middleware['isTenantManager'] = require('../middleware/isTenantManager.js')
middleware['isTenantManager'] = middleware['isTenantManager'].default || middleware['isTenantManager']

middleware['isTenantOwner'] = require('../middleware/isTenantOwner.js')
middleware['isTenantOwner'] = middleware['isTenantOwner'].default || middleware['isTenantOwner']

middleware['isUnderMaintenance'] = require('../middleware/isUnderMaintenance.js')
middleware['isUnderMaintenance'] = middleware['isUnderMaintenance'].default || middleware['isUnderMaintenance']

middleware['isUser'] = require('../middleware/isUser.js')
middleware['isUser'] = middleware['isUser'].default || middleware['isUser']

middleware['locale'] = require('../middleware/locale.js')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['onboarding'] = require('../middleware/onboarding.js')
middleware['onboarding'] = middleware['onboarding'].default || middleware['onboarding']

middleware['utm'] = require('../middleware/utm.js')
middleware['utm'] = middleware['utm'].default || middleware['utm']

export default middleware
