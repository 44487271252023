
export default {
    name: 'Modal',
    props: {
        headerClass: {
            type: String,
            default: 'bg-brand-25'
        },
        withHeader: {
            type: Boolean,
            default: true
        },
        withClose: {
            type: Boolean,
            default: true
        },
        backgroundTheme: {
            type: String,
            default: 'gray',
            validator (value) {
                return ['blur', 'gray'].includes(value)
            }
        },
        size: {
            type: String,
            default: 'sm',
            validator (value) {
                return ['sm', 'md', 'md-extended', 'lg', 'full'].includes(value)
            }
        }
    },
    data () {
        return {
            screenType: 'phone',
            headerHeight: 0
        }
    },
    destroyed () {
        document.body.style.overflow = 'visible'
    },
    mounted () {
        document.body.style.overflow = 'hidden'
        this.headerHeight = document.querySelector('#headerBar')?.offsetHeight ?? 0
        const width = window.innerWidth
        if (width < 1024) {
            this.screenType = 'phone'
        } else if (width < 1400) {
            this.screenType = 'tablet'
        } else {
            this.screenType = 'desktop'
        }
    }
}
