
import OwnerApprovedTrialModal from '@/components/English/OwnerApprovedTrialModal.vue'

export default {
    name: 'EnglishTrial',
    components: {
        OwnerApprovedTrialModal,
    },
    data () {
        return {
            subscription: null,
            type: null,
            modal: null
        }
    },
    computed: {
        hasEnglishTrial () {
            return !!this.$auth.user.extra?.english_trial
        },

        getEnglishTrial () {
            return this.hasEnglishTrial ? this.$auth.user.extra?.english_trial : null
        },

        isActive () {
            return this.getEnglishTrial !== 'expired'
        },

        getStatus () {
            if (this.hasEnglishTrial) {
                return this.getEnglishTrial?.status ?? null
            }

            return null
        },

        timeDifference () {
            if (!this.hasEnglishTrial) { return null }

            const { days, hours, minutes } = this.getEnglishTrial
            const timeFormat = new Intl.RelativeTimeFormat(this.$helpers.getLocale(), { numeric: 'auto' })

            const timeValue = days || hours || minutes
            const timeUnit = days ? 'day' : hours ? 'hour' : 'minutes'

            return timeValue ? timeFormat.format(timeValue, timeUnit).replace('خلال', '').replace('in', '').trim() : null;
        }
    },
    created () {
        if (this.getStatus === 'OwnerApproved') {
            this.modal = 'owner-approved'
        }
    }
}
